import React from 'react'
import {
  Container,
  LinkButton,
  Section,
  Seo,
} from '@components'
import { LandingPageLayout } from '../../../components/site/layout/landing-page-layout'

const videoId = 'lOsZco0X2YY'

const In30WatchPage = ({ location }) => {
  return (
    <LandingPageLayout pathname={location.pathname}>
      
      <Section className="pt-16 pb-8 bg-white">
        <Container className="container-md">
          <div className="flex flex-col items-center">
            <h1 className="mb-12 text-5xl text-center">
              Best-in-Class Mobile App and API Defense, in 30 Minutes
            </h1>
            <h2 className="mt-0 mb-6 text-gray-800">12 April 2022</h2>
            <p>
              Watch and learn as Skip sets up Approov protection in under 30
              minutes - for both iOS and Android, protecting against:
            </p>
            <ul className="mb-6">
              <li>
                Bots, scripts, or repackaged or cloned apps targeting your APIs
              </li>
              <li>
                MitM or "Man-in-the- Device" attacks intercepting your
                communications
              </li>
              <li>
                Apps running on mobile devices which are rooted/jailbroken or
                attached to emulators, debuggers or other frameworks
              </li>
            </ul>

            <div className="flex items-center justify-center w-full">
              <div className="w-full border border-gray-300 aspect-w-16 aspect-h-9">
                <iframe
                  title="Best-in-Class Mobile App and API Defense, in 30 Minutes"
                  src={`https://www.youtube-nocookie.com/embed/${videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>

            <p>
              Approov can help your business secure its mobile APIs quickly and
              effectively. Want to learn more about how we helped dozens of
              companies ensure that only their mobile apps can use their APIs?
              Talk to us.
            </p>

            <LinkButton
              className="button-secondary button-lg"
              href="/product/consult"
            >
              Talk to an Expert
            </LinkButton>
          </div>
        </Container>
      </Section>
    </LandingPageLayout>
  )
}

export default In30WatchPage
